import Block from '../block'

export default class ProductImages extends Block {
	static title = 'product-images'

	constructor($el) {
		super($el)

		const carousel = this.$el.findElement('carousel')
		const scroller = this.$el.findElement('thumbnails')
		const thumbnails = this.$el.findElement('thumbnail')

		scroller.on('click', (e) => {
			const target = e.target

			if ($(target).is('img')) {
				const index = parseInt($(target).data('index'))
				carousel.get(0).goToSlide(index)
			}
		})

		carousel.on('sl-slide-change', (e) => {
			const slideIndex = e.detail.index

			thumbnails.each((i, thumb) => {
				thumb.classList.toggle(
					'product-images__thumbnail--active',
					i === slideIndex,
				)

				if (i === slideIndex) {
					thumb.scrollIntoView({
						block: 'nearest',
					})
				}
			})
		})
	}
}

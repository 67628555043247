import Block from '../block'

export default class Nav extends Block {
	static title = 'nav'

	constructor($el) {
		super($el)
		// show collapsed menu when too many items in the nav
		// get witdh of nav items
		const nav_item_width = this.$el.findElement('items').width()
		this.resizeMenu(nav_item_width)
		window.addEventListener('resize', () => this.resizeMenu(nav_item_width))

		// open side menu
		this.$el.findElement('toggle').on('click', () => {
			this.$el.findElement('open').toggleClass('mobile:hidden')
			this.$el.findElement('close').toggleClass('mobile:hidden')
			this.$el.findElement('items').toggleClass('mobile:hidden')
			this.$el.findElement('popup').toggleClass('desktop:hidden')
			this.$el.findElement('subs').addClass('hidden')
		})

		this.$el.findElement('popup-close').on('click', () => {
			this.$el.findElement('popup').toggleClass('desktop:hidden')
			this.$el.findElement('items').toggleClass('mobile:hidden')
			this.$el.findElement('close').addClass('mobile:hidden')
			this.$el.findElement('open').removeClass('mobile:hidden')
		})

		this.$el.findElement('popup-overlay').on('click', () => {
			this.$el.findElement('popup-close').trigger('click')
		})

		this.$el.findElement('subs-toggle').on('click', () => {
			this.$el.findElement('subs').toggleClass('hidden')
		})

		this.$el.findElement('back').on('click', () => {
			this.$el.findElement('subs').toggleClass('hidden')
		})

		this.$el.findElement('shop-item').on('click', () => {
			$(document).trigger('shoppingcart:open')
		})

		$(document).on('product:add', (index, data) => {
			this.$el.findElement('count--span').text(data.count)
		})

		$(document).on('product:remove', (index, data) => {
			this.$el.findElement('count--span').text(data.count)
		})

		$(document).on('product:update', (index, data) => {
			this.$el.findElement('count--span').text(data.count)
		})
	}

	resizeMenu(nav_item_width) {
		const nav_width_diff_percentage =
			(nav_item_width / this.$el.findElement('main').parent().outerWidth()) *
			100
		if (nav_width_diff_percentage >= 80) {
			this.$el.findElement('items').addClass('desktop:hidden')
			this.$el.findElement('toggle').removeClass('desktop:hidden')
		} else {
			this.$el.findElement('popup').addClass('desktop:hidden')
			this.$el.findElement('items').removeClass('desktop:hidden')
			this.$el.findElement('toggle').addClass('desktop:hidden')
		}
	}
}

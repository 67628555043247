import $ from 'jquery'

import Block from '../block'

export default class Colors extends Block {
	static title = 'colors'

	constructor($el) {
		super($el)

		this.select = this.$el.findElement('select')

		this.select.on('sl-change', (e) => {
			const selected = $(e.target).find(
				'.colors__select-option[aria-selected="true"]',
			)
			$(document).trigger('color:change', {
				color_id: $(e.target).val(),
				title: selected.data('title'),
				stock: selected.data('stock'),
				basePrice: this.$el.data('basePrice'),
			})
		})
	}
}

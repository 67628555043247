import Block from '../block'

export default class ShoppingcartTotal extends Block {
	static title = 'shoppingcart-total'

	constructor($el) {
		super($el)

		if (this.$el.hasModifier('confirmation')) {
			return
		}

		this.subtotal1 = this.$el.findElement('subtotal-excl')
		this.subtotal2 = this.$el.findElement('subtotal-incl')
		this.total = this.$el.findElement('total')

		$(document).on('product:add', (index, data) => {
			this.updateComponent(data)
		})

		$(document).on('product:remove', (index, data) => {
			this.updateComponent(data)
		})

		$(document).on('product:update', (index, data) => {
			this.updateComponent(data)
		})
	}

	updateComponent(data) {
		this.subtotal1.text(data.subtotal1)
		this.subtotal2.text(data.subtotal2)
		this.total.text(data.total)
	}
}

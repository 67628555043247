import $ from 'jquery'

import Block from '../block'

export default class ProductInfo extends Block {
	static title = 'product-info'

	constructor($el) {
		super($el)

		this.addButton = this.$el.findElement('button')

		$(document).on('color:change', (e, data) => {
			this.$el.findElement('option--colors').text(data.title)

			if (data.stock <= 0) {
				this.$el.findElement('button').prop('disabled', true)
			} else {
				this.$el.findElement('button').prop('disabled', false)
			}

			this.$el.findElement('price').text(data.basePrice)
		})

		$(document).on('size:change', (e, data) => {
			this.$el.findElement('option--sizes').text(data.title)

			if (data.stock <= 0) {
				this.$el.findElement('button').prop('disabled', true)
			} else {
				this.$el.findElement('button').prop('disabled', false)
			}

			this.$el.findElement('price').text(data.price)
		})

		this.$el.on('submit', (e) => this.handleSubmit(e))
	}

	handleSubmit(e) {
		e.preventDefault()

		const data = new FormData(this.$el.get(0))

		// color and size are required
		if (data.has('color_id') === false || data.has('size_id') === false) {
			$(document).trigger('message:error', this.$el.data('validationLabel'))
			return null
		}

		data.append('ajax', true)

		this.setLoading(true)

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/shoppingcart',
			data: data,
		})
			.done((json) => {
				// check for errors
				if (!json.error) {
					$(document).trigger('product:add', json)
				} else {
					$(document).trigger('message:error', json.error)
				}
			})
			.fail((error) => {
				$(document).trigger('message:error', error.message)
			})
			.always(() => this.setLoading(false))
	}

	setLoading(bool) {
		if (bool) {
			this.addButton.find('i').addClass('hidden')
			this.addButton.find('sl-spinner').removeClass('hidden')
		} else {
			this.addButton.find('i').removeClass('hidden')
			this.addButton.find('sl-spinner').addClass('hidden')
		}
	}
}

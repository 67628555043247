import Block from '../block'

export default class ShoppingcartAddress extends Block {
	static title = 'shoppingcart-address'

	constructor($el) {
		super($el)

		this.invoiceRadios = this.$el.find('sl-radio-group[name="invoice-fields"]')
		this.invoiceField = this.$el.findElement('invoice-field')

		this.invoiceRadios.on('sl-change', (e) => {
			if (e.currentTarget.value == 1) {
				this.invoiceField.removeClass('hidden')
				this.invoiceField.find('sl-select').prop('disabled', false)
			} else {
				this.invoiceField.addClass('hidden')
				this.invoiceField.find('sl-select').prop('disabled', true)
			}
		})
	}
}

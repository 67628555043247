import $ from 'jquery'

export default class ComponentFactory {
	static globalHandlers = {}

	static create(instance, element, loadDependencies = true) {
		const $el = $(element)

		element.ref = new instance($el)

		/**
		 * Global handlers can be used whenever a component is subscribed an a callback on the window
		 */
		if (typeof element.ref.getGlobalHandler === 'function') {
			const [method, callback] = element.ref.getGlobalHandler()
			if (this.globalHandlers[method]) {
				this.globalHandlers[method] = [...this.globalHandlers[method], callback]
			} else {
				this.globalHandlers[method] = [callback]
			}
		}

		Object.keys(this.globalHandlers).map((method) => {
			window[method] = () => {
				this.globalHandlers[method].forEach((callback) => callback())
			}
		})

		/**
		 * Some components require dependencies, for example google maps API
		 */
		if (loadDependencies && typeof element.ref.getDependency === 'function') {
			const firstScriptTag = $('script').get(0)
			const tag = document.createElement('script')
			tag.defer = true
			tag.async = true
			if (typeof element.ref.load === 'function') {
				tag.onload = element.ref.load.bind(element.ref)
			}
			tag.src = element.ref.getDependency()
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
		}
	}
}

import $ from 'jquery'

import Block from '../block'

export default class Sizes extends Block {
	static title = 'sizes'

	constructor($el) {
		super($el)

		this.select = this.$el.findElement('select')

		$(document).on('color:change', (e, data) => {
			if (this.$el.data('colorId') == data.color_id) {
				this.$el.removeClass('hidden')
				this.select.prop('disabled', false)
			} else {
				this.$el.addClass('hidden')
				this.select.prop('disabled', true)
			}

			this.select.val('')
		})

		this.select.on('sl-change', (e) => {
			const selected = $(e.target).find(
				'.sizes__select-option[aria-selected="true"]',
			)
			$(document).trigger('size:change', {
				title: selected.data('title'),
				stock: selected.data('stock'),
				price: selected.data('price'),
			})
		})
	}
}

import Block from '../block'
import intlTelInput from 'intl-tel-input/build/js/intlTelInputWithUtils'

export default class PhoneField extends Block {
	static title = 'phone-field'

	constructor($el) {
		super($el)

		// try to get json translated text
		try {
			this.translations = JSON.parse(this.$el.findElement('errors').text())
		} catch (e) {
			this.translations = null
		}

		this.phoneField = this.$el.findElement('input')
		this.input = intlTelInput(this.phoneField.get(0), {
			nationalMode: false,
			separateDialCode: true,
			initialCountry: 'auto',
			autoInsertDialCode: true,
			formatOnDisplay: false,
			hiddenInput: function () {
				return {
					phone: 'phone',
					country: 'country_code',
				}
			},
			geoIpLookup: (callback) => {
				fetch('https://ipapi.co/json')
					.then((res) => res.json())
					.then((data) => callback(data.country_code))
					.catch(() => callback('nl'))
			},
		})

		if (this.$el.data('phone')) {
			this.input.setNumber(this.$el.data('phone'))
		}

		this.phoneField.on('blur', () => {
			if (this.phoneField.val()) {
				if (!this.input.isValidNumber()) {
					if (this.translations) {
						const errorCode = this.input.getValidationError()
						$(document).trigger('message:error', {
							error: this.translations[errorCode],
						})
					} else {
						$(document).trigger('message:error', {
							error: this.$el.data('validation'),
						})
					}
				} else {
					// TODO: add success styling to element
				}
			}
		})
	}
}

import Block from '../block'

export default class ShoppingcartProduct extends Block {
	static title = 'shoppingcart-product'

	constructor($el) {
		super($el)

		this.delete = this.$el.findElement('delete')
		this.min = this.$el.findElement('min')
		this.plus = this.$el.findElement('plus')
		this.countInput = this.$el.findElement('count')

		this.delete.on('click', () => this.deleteProduct())

		this.min.on('click', () => this.updateProduct('min'))
		this.plus.on('click', () => this.updateProduct('plus'))
		this.countInput.on('sl-change', () => this.updateProduct())
	}

	deleteProduct() {
		this.setDeleteLoading(true)
		const data = new FormData()

		data.append('ajax', true)
		data.append('id', this.$el.data('id'))
		data.append('delete', true)

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/shoppingcart',
			data: data,
		})
			.done((json) => {
				// check for errors
				if (json.status) {
					$(document).trigger('product:remove', json)
					// remove element from DOM
					this.$el.remove()
				} else {
					$(document).trigger('message:error', json.error)
				}
			})
			.fail((error) => {
				$(document).trigger('message:error', error.message)
			})
			.always(() => this.setDeleteLoading(false))
	}

	updateProduct(type = null) {
		this.setNumberLoading(true)
		const data = new FormData()

		data.append('ajax', true)
		data.append('id', this.$el.data('id'))
		data.append('update', true)
		let count = parseInt(this.countInput.val())

		// check if count is changed with icon or not
		if (type == 'min') {
			count = count - 1
		}

		if (type == 'plus') {
			count = count + 1
		}

		data.append('count', count)

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/shoppingcart',
			data: data,
		})
			.done((json) => {
				// check for errors
				if (!json.error) {
					// remove if count is 0 or lower
					if (count <= 0) {
						this.$el.remove()
					} else {
						this.countInput.val(count)
					}
					$(document).trigger('product:update', json)
				} else {
					$(document).trigger('message:error', json.error)
				}
			})
			.fail((error) => {
				$(document).trigger('message:error', error.message)
			})
			.always(() => this.setNumberLoading(false))
	}

	setDeleteLoading(bool) {
		if (bool) {
			this.$el.findElement('loader').removeClass('hidden')
		} else {
			this.$el.findElement('loader').addClass('hidden')
		}
	}

	setNumberLoading(bool) {
		if (bool) {
			this.$el.findElement('number').addModifier('disabled')
		} else {
			this.$el.findElement('number').removeModifier('disabled')
		}
	}
}

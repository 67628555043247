import Block from '../block'

export default class BlockGallery extends Block {
	static title = 'block-gallery'

	constructor($el) {
		super($el)

		$(document).on('carousel:change-slide', (event, data) => {
			if (this.$el.data('id') == data.id) {
				this.$el.findElement('title').text(data.title)
				this.$el.findElement('text').html(data.caption)
			}
		})
	}
}

import Block from '../block'

export default class BlockCarousel extends Block {
	static title = 'block-carousel'

	constructor($el) {
		super($el)

		this.$el.on('sl-slide-change', () => {
			const activeSlide = this.$el.find('.--is-active')

			$(document).trigger('carousel:change-slide', {
				id: this.$el.data('id'),
				title: activeSlide.data('title'),
				caption: activeSlide.data('caption'),
			})
		})
	}
}

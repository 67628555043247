import Block from '../block'

export default class BlockForm extends Block {
	static title = 'block-form'

	constructor($el) {
		super($el)

		if (this.$el.hasModifier('lightbox')) {
			// for lightbox open
			this.toggleButton = this.$el.findElement('toggle')
			// for lightbox close
			this.closeButton = this.$el.findElement('close')
			this.cancelButton = this.$el.findElement('cancel')
			this.lightboxOverlay = this.$el.findElement('fields-overlay')

			this.toggleButton.on('click', () => {
				// use modifier because of precedence
				this.$el.addModifier('lightbox-active')
				// prevent site from scrolling while the lightbox is open
				$('body').css('overflow', 'hidden')
			})

			this.closeButton
				.add(this.cancelButton)
				.add(this.lightboxOverlay)
				.on('click', () => {
					this.$el.removeModifier('lightbox-active')
					// revert body overflow so scrolling is possible again
					$('body').css('overflow', 'auto')
				})
		}
	}
}

import Block from '../block'
import ApexCharts from 'apexcharts'

export default class Chart extends Block {
	static title = 'chart'

	constructor($el) {
		super($el)

		if (this.$el.hasModifier('pie')) {
			const values = this.$el.data('value').split(' ').map(Number)
			const options = {
				series: values,
				chart: {
					width: 380,
					type: 'pie',
				},
				labels: ['Recycled', 'Reused'],
				dataLabels: {
					enabled: false,
				},
				legend: {
					show: false,
				},
				plotOptions: {
					pie: {
						donut: {
							labels: {
								show: true,
								total: {
									show: true,
									label: this.$el.data('title'),
									formatter: function () {
										return values[0] + '%'
									},
								},
							},
						},
					},
				},
			}

			const chart = new ApexCharts(
				this.$el.findElement('element').get(0),
				options,
			)
			chart.render()
		}
	}
}
